import React, {useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import styles from '../styles/pages/HsTms.module.scss';

import Popup from '../components/common/popup/Popup';
import PopupConsult from '../assets/popup/PopupConsult';


import allinonecross from "../assets/images/tms_all_in_one_cross.svg";
import allinonebigdata from "../assets/images/tms_all_in_one_bigdata.svg";
import allinoneonestep from "../assets/images/tms_all_in_one_one_step.svg";
import allinonechannel from "../assets/images/tms_all_in_one_channel.svg";
import clientlogo from "../assets/images/tms_client_logo.png";
import {useStore} from "react-redux";

function HsTms() {
    const [showConsultPopup, setShowConsultPopup] = useState(false);
    const [showServicePopup, setShowServicePopup] = useState(false);

    const store = useStore();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // 25-02-26 utm_source로 광고 불가 이슈로 주석 처리
    // const utmSource = queryParams.get('utm_source');
    // useEffect(() => {
    //     if (utmSource) {
    //         handleShowConsultPopup();
    //     }
    // }, []);
    const handleShowConsultPopup = () => {
        setShowConsultPopup(true);
    }
    const handleShowServicePopup = () => {
        setShowServicePopup(true);
    }
    function handleClose() {
        setShowConsultPopup(false);
    }

    function handleCloseServicePopup() {
        setShowServicePopup(false);
    }

    function handleClick(type) {
        const { consultData } = store.getState().popupDataReducer;
        requestConsult(consultData, type);
    }
    function requestConsult(formData, type) {
        const { company, name, contact, email, content } = formData;

        if(!company || company.trim() === '') {
            alert('회사명/소속을 입력해주세요.');
            return;
        } else if(!name || name.trim() === '') {
            alert('성함/직책을 입력해주세요.');
            return;
        } else if(!contact || contact.trim() === '') {
            alert('연락처를 입력해주세요.');
            return;
        } else if(!email || email.trim() === '') {
            alert('메일주소를 입력해주세요.');
            return;
        } else if(!content || content.trim() === '') {
            alert('문의내용을 입력해주세요.');
            return;
        }

        let keyword = '';
        // const type = 1; // qna일 경우 1, 서비스 소개서 신청일 경우 2
        const volume = '0';
        const params = {
            type,
            company,
            name,
            contact,
            email,
            content,
            keyword,
            volume
        }
        const url = "https://imc.humuson.com/api/contact/v1/sendMail";
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then((res) => {
                alert(res.message);
                const method = type === 1 ? setShowConsultPopup : setShowServicePopup;
                method(false);
            })
            .catch(error => {
                // 오류 처리
            });
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const utm_source = queryParams.get('utm_source');
        const utm_medium = queryParams.get('utm_medium');
        const utm_campaign = queryParams.get('utm_campaign');
        if (utm_source && utm_medium && utm_campaign) {
            const section = document.getElementById('tms_introduce');
            section.scrollIntoView({ block: 'start' })
            // handleShowServicePopup(); 영업팀 요청사항으로 미사용 처리
        }
    }, []);
    return (
        <>
            <section className="hs-section">
                <div className="hs-section-inner">
                    <h2 className={styles['hs-main-title']}>All-in-one <span className="text-bold text-block">통합 채널&마케팅 솔루션, TMS</span></h2>
                    <p className={styles['hs-main-desc']}>
                        TMS Enterprise Solution은 이메일/푸시/문자/카카오비즈 메시지 등의 채널 통합과 실시간 데이터를 기반으로 마케팅 자동화를 지원하는 솔루션입니다.
                    </p>
                    <ul className={styles['hs-main-list']}>
                        <li className={styles['hs-main-item']}>
                            <img src={allinonechannel} alt="통합 멀티 채널" />
                        </li>
                        <li className={styles['hs-main-item']}>
                            <img src={allinonecross} alt="크로스 채널 서비스" />
                        </li>
                        <li className={styles['hs-main-item']}>
                            <img src={allinoneonestep} alt="One Step 마케팅" />
                        </li>
                        <li className={styles['hs-main-item']}>
                            <img src={allinonebigdata} alt="빅데이터 마케팅" />
                        </li>
                    </ul>
                </div>
            </section>
            <section className="hs-section gray" id="tms_introduce">
                <h3 className="blind">TMS 소개</h3>
                <div className="hs-section-inner">
                    <strong className={`${styles['hs-sub-title']} text-center`}>통합 메시징 X 빅데이터 마케팅, TMS</strong>
                    <p className={styles['hs-main-desc']}>
                        채널통합으로 비용은 절감하고, 효율성을 높일 수 있습니다. <br aria-hidden="true" />
                        실시간, 개인화, 자동화로 마케팅 성과를 제고할 수 있습니다.
                    </p>
                    <div className={styles['hs-button-container']}>
                        <button type="button" className={`${styles['hs-button']} ${styles['primary']}`} onClick={handleShowConsultPopup}>상담신청</button>
                        <button type="button" className={`${styles['hs-button']}`} onClick={handleShowServicePopup}>서비스소개서 신청</button>
                    </div>
                </div>
                <div className="hs-section-inner">
                    <strong className={styles['hs-sub-title']}>채널 통합, <span className="text-bold">성과의 시작입니다.</span></strong>
                    <ul className={styles['hs-tms-intro-list']}>
                        <li className={`${styles['hs-tms-intro-item']} ${styles['cost']}`}>
                            <div className={styles['hs-tms-intro-item-image']}></div>
                            <div className={styles['hs-tms-intro-item-inner']}>
                                <strong className={styles['hs-tms-intro-title']}>비용 절감</strong>
                                <p className={styles['hs-tms-intro-desc']}>하나의 솔루션에서 모든 채널을 통합 운영함에 따라 구축 및 유지보수 비용은 물론 업무 효율성을 강화할 수 있습니다. 한번에 모든 채널을 구축하지 않고 필요에 따라 Add-on 방식으로 간편하게 추가할 수 있습니다.</p>
                            </div>
                        </li>
                        <li className={`${styles['hs-tms-intro-item']} ${styles['cross']}`}>
                            <div className={styles['hs-tms-intro-item-image']}></div>
                            <div className={styles['hs-tms-intro-item-inner']}>
                                <strong className={styles['hs-tms-intro-title']}>크로스 채널</strong>
                                <p className={styles['hs-tms-intro-desc']}>하나의 캠페인에서 복합 채널로 발송하거나, 시나리오에 따른 자동발송, 고객 선호 채널 분석 등 유연한 채널간 Cross 및 연계 서비스를 제공합니다.</p>
                            </div>
                        </li>
                        <li className={`${styles['hs-tms-intro-item']} ${styles['data']}`}>
                            <div className={styles['hs-tms-intro-item-image']}></div>
                            <div className={styles['hs-tms-intro-item-inner']}>
                                <strong className={styles['hs-tms-intro-title']}>데이터 기반 실시간 캠페인 및 초개인화</strong>
                                <p className={styles['hs-tms-intro-desc']}>자사 웹사이트 또는 모바일앱에 접속하는 고객들의 행동데이터를 연계하여 타겟팅에서 발송까지 실시간 캠페인을 실행할 수 있으며, 고객별 개인화콘텐츠를 통해 성과를 제고할 수 있습니다.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="hs-section">
                <h3 className="blind">TMS 경쟁력</h3>
                <div className="hs-section-inner">
                    <strong className={styles['hs-sub-title']}>TMS의 경쟁력은 <span className="text-bold text-block">'기능'이 아닌 고객을 바라보는 '관점'입니다.</span></strong>
                    <ul className={styles['hs-tms-competitiveness-list']}>
                        <li className={`${styles['hs-tms-competitiveness-item']} ${styles['performance']}`}>
                            <div className={styles['hs-tms-competitiveness-image']}></div>
                            <div className={styles['hs-tms-competitiveness-inner']}>
                                <strong className={styles['hs-tms-competitiveness-title']}>고객의 성과가 기준입니다.</strong>
                                <p className={styles['hs-tms-competitiveness-desc']}>
                                    모든 채널의 원천기술을 기반으로 한 자사제품으로 구성하여, 금융, 유통, 서비스, 제조 등 다양한 업종에서의 다양한 고객니즈에 안정적이면서도 유연하게 대응할 수 있습니다.
                                </p>
                            </div>
                        </li>
                        <li className={`${styles['hs-tms-competitiveness-item']} ${styles['technology']}`}>
                            <div className={styles['hs-tms-competitiveness-image']}></div>
                            <div className={styles['hs-tms-competitiveness-inner']}>
                                <strong className={styles['hs-tms-competitiveness-title']}>기술력은 Default입니다.</strong>
                                <p className={styles['hs-tms-competitiveness-desc']}>
                                    TMS는 단지 많은 기능을 제공하는 것이 아닌 고객이 목표를 달성하거나 최고의 성과를 내기 위해 각각의 기능을 어떻게 접목할지 고민합니다.
                                </p>
                            </div>
                        </li>
                        <li className={`${styles['hs-tms-competitiveness-item']} ${styles['marketing']}`}>
                            <div className={styles['hs-tms-competitiveness-image']}></div>
                            <div className={styles['hs-tms-competitiveness-inner']}>
                                <strong className={styles['hs-tms-competitiveness-title']}>본질은 마케팅입니다.</strong>
                                <p className={styles['hs-tms-competitiveness-desc']}>
                                    웹사이트/앱에 방문하는 고객의 실시간 데이터를 기반으로 데이터 수집/분석, 타겟팅, 개인화콘텐츠 제작, 캠페인 실행까지 마케팅 프로세스를 자동화합니다.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="hs-section gray">
                <div className="hs-section-inner">
                    <h3 className={`${styles['hs-sub-title']} text-bold`}>TMS 채널 안내</h3>
                    <ul className={styles['hs-tms-channel-list']}>
                        <li className={styles['hs-tms-channel-item']}>
                            <strong className={styles['hs-tms-channel-title']}>E-mail</strong>
                            <ul className={styles['hs-tms-channel-sub-list']}>
                                <li className={styles['hs-tms-channel-sub-item']}>대량발송 시간당 30만건 이상, 자동발송 시간당 10만건 이상 발송 / 수신서버 및 KISA 정책 최적화</li>
                                <li className={styles['hs-tms-channel-sub-item']}>메일오픈, 링크클릭 등 수신자 반응을 시간대별, 기기(OS)별 분석 및 리포팅</li>
                                <li className={styles['hs-tms-channel-sub-item']}>파일등록, 고객사 DB연동, 고객반응 데이터 연계 등 다양한 타겟팅 방식 제공</li>
                            </ul>
                        </li>
                        <li className={`${styles['hs-tms-channel-item']} ${styles['push']}`}>
                            <strong className={styles['hs-tms-channel-title']}>PUSH</strong>
                            <ul className={styles['hs-tms-channel-sub-list']}>
                                <li className={styles['hs-tms-channel-sub-item']}>Open API를 활용한 Public Push : 국내 최대의 구축 레퍼런스 보유</li>
                                <li className={styles['hs-tms-channel-sub-item']}>자체서버 기반 Private Push : 99% 발송성공률, 초당 1,000건 발송 가능</li>
                                <li className={styles['hs-tms-channel-sub-item']}>팝업, 슬라이드, 인-앱 메시지 등 다양한 방식으로 푸시콘텐츠 노출이 가능합니다.</li>
                            </ul>
                            <div className={styles['hs-tms-channel-image']}></div>
                        </li>
                        <li className={`${styles['hs-tms-channel-item']} ${styles['sms']}`}>
                            <strong className={styles['hs-tms-channel-title']}>문자</strong>
                            <ul className={styles['hs-tms-channel-sub-list']}>
                                <li className={styles['hs-tms-channel-sub-item']}>발송라인 병렬구성을 통한 시간당 150만 건 이상 발송</li>
                                <li className={styles['hs-tms-channel-sub-item']}>명절 등 시즈널 이슈는 이벤트 전용라인을 통해 평소 대비 1.5배 이상의 발송속도 구현</li>
                                <li className={styles['hs-tms-channel-sub-item']}>AP서버-중계서버간 AES암호화 방식 적용으로 보안강화</li>
                            </ul>
                            <div className={styles['hs-tms-channel-image']}></div>
                        </li>
                        <li className={styles['hs-tms-channel-item']}>
                            <strong className={styles['hs-tms-channel-title']}>알림톡 친구톡</strong>
                            <ul className={styles['hs-tms-channel-sub-list']}>
                                <li className={styles['hs-tms-channel-sub-item']}>알림톡/친구톡은 휴대폰 번호로 카카오톡을 통해 메시지를 발송하는 기업전용 메시징 상품입니다.</li>
                                <li className={styles['hs-tms-channel-sub-item']}>알림톡/친구톡 발송이 실패한 경우에도 2차 문자 발송을 통해 도달률을 높일 수 있습니다.</li>
                                <li className={styles['hs-tms-channel-sub-item']}>카카오비즈메시지 정식 딜러사로서, 고객요구에 따라 구축형/서비스형 모두 가능합니다.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="hs-section">
                <div className="hs-section-inner">
                    <h3 className={styles['hs-sub-title']}>주요고객 <span className="text-bold text-block">지난 20년간 다양한 업종의 고객사께서 증명해주셨습니다.</span></h3>
                    <div  className={styles['hs-tms-client-logo-wrap']}>
                        <img src={clientlogo} alt="클라이언트 로고" className={styles['hs-tms-client-logo']} />
                    </div>
                </div>
            </section>
            {
                showConsultPopup &&
                <Popup
                    onClose={handleClose}
                    contentTitle={"문의/상담 신청"}
                    contentComponent={PopupConsult}
                    popupConstructor={'PopupConsult'}
                    buttonPrimary={"보내기"}
                    buttonPrimaryClick={(data) => handleClick(1)}
                />
            }
            {
                showServicePopup &&
                <Popup
                    onClose={handleCloseServicePopup}
                    contentTitle={"서비스 소개 신청"}
                    popupConstructor={'PopupConsult'}
                    contentComponent={PopupConsult}
                    buttonPrimary={"보내기"}
                    buttonPrimaryClick={(data) => handleClick(2)}
                />
            }
        </>
    );
}

export default HsTms;