import React from 'react';
import styles from '../styles/pages/HsSuccess.module.scss';

function HsSuccess() {
    const customers = [
        {
            logo: require('../assets/images/success/01_1_samsung.png'),
            name: '삼성전자',
            description: '이메일 솔루션 암호화 적용',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/01_2_korea air.png'),
            name: '대한항공',
            description: 'TMS Cloud 및 운영 서비스',
            service: 'TMS Cloud, 2024'
        },
        {
            logo: require('../assets/images/success/01_3_hyundai_A.png'),
            name: '현대백화점',
            description: '통합메시징 캠페인 시스템 구축',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/02_1_starbucks.png'),
            name: '스타벅스',
            description: 'Push 솔루션 고도화',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/02_2_UHAN.png'),
            name: '유한킴벌리',
            description: 'TMS Cloud 및 운영 서비스',
            service: 'TMS Cloud, 2024'
        },
        {
            logo: require('../assets/images/success/02_3_samsung_insul.png'),
            name: '삼성생명보험',
            description: '삼성생명 UMS통합 솔루션 구축',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/03_1_hanssem.png'),
            name: '한샘',
            description: '메시지 발송 시스템 유지보수',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/03_2_smilegate.png'),
            name: '스마일게이트',
            description: '통합 메시지 솔루션 구축',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/03_3_yonhap.png'),
            name: '연합뉴스',
            description: 'TMS Cloud 및 운영 서비스',
            service: 'TMS Cloud, 2024'
        },
        {
            logo: require('../assets/images/success/04_1_lotte.png'),
            name: '롯데백화점',
            description: '통합 솔루션 DBMS 업그레이드',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/04_2_samsung_card.png'),
            name: '삼성카드',
            description: '카카오 메시지 추가 개선 개발',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/04_3_wconcept.png'),
            name: '더블유컨셉코리아',
            description: 'TMS Cloud 및 운영 서비스',
            service: 'TMS Cloud, 2024'
        },
        {
            logo: require('../assets/images/success/05_1_nh.png'),
            name: 'NH투자증권',
            description: 'EMS 시스템 유지보수',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/05_2_asiana.png'),
            name: '아시아나 항공',
            description: '앱푸시 발송 솔루션 유지보수',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/05_3_bc.png'),
            name: '비씨카드',
            description: 'BC카드 유지보수',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/06_1_cj.png'),
            name: 'CJ푸드빌',
            description: '메시지 발송 성능 향상 고도화',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/06_2_hanwha.png'),
            name: '한화오션',
            description: '이메일 시스템 구축',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/06_3_monimo.png'),
            name: '모니모',
            description: '통합 메시지 솔루션 구축',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/07_1_multicampus.png'),
            name: '멀티캠퍼스',
            description: 'TMS Cloud 및 운영 서비스',
            service: 'TMS Cloud, 2024'
        },
        {
            logo: require('../assets/images/success/07_2_tway.png'),
            name: '티웨이항공',
            description: '이메일 시스템 업그레이드',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/07_3_heungkuk.png'),
            name: '흥국화재',
            description: '이메일 시스템 구축',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/08_1_acqueon.png'),
            name: '애큐온캐피탈',
            description: '이메일 시스템 업그레이드',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/08_2_samsung_welfare.png'),
            name: '삼성복지재단',
            description: 'TMS Cloud 및 운영 서비스',
            service: 'TMS Cloud, 2024'
        },
        {
            logo: require('../assets/images/success/08_3_mobility.png'),
            name: '이동의즐거움',
            description: '통합 메시지 솔루션 유지보수',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/09_1_abl.png'),
            name: 'ABL생명',
            description: '솔루션 DBMS 업그레이드',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/09_2_xlgames.png'),
            name: '엑스엘게임즈',
            description: 'TMS Cloud 및 운영 서비스',
            service: 'TMS Cloud, 2024'
        },
        {
            logo: require('../assets/images/success/09_3_hanatour.png'),
            name: '하나투어',
            description: '발송 성능 향상 고도화',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/10_1_hanamoney.png'),
            name: '하나머니',
            description: '이메일 솔루션 업그레이드',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/10_2_paris.png'),
            name: '파리크라상',
            description: 'TMS Cloud 및 운영 서비스',
            service: 'TMS Cloud, 2024'
        },
        {
            logo: require('../assets/images/success/10_3_hanpass.png'),
            name: '한패스',
            description: '통합 메시지 솔루션 구축',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/11_1_dongyang.png'),
            name: '동양생명',
            description: '대량메일 발송 솔루션 유지보수',
            service: 'TMS, 2024'
        },
        {
            logo: require('../assets/images/success/11_2_pizzahut.png'),
            name: '피자헛',
            description: '통합 메시지 서비스 계약',
            service: 'TMS, 2024'
        }
    ];

    return (
        <>
            <div className={styles['hs-visual-section']}>
                <strong>성공 사례</strong>
            </div>
            <section className={styles['hs-success-section']}>
                <div className={styles['hs-success-inner']}>
                    <ul className={styles['hs-success-list']}>
                        {customers.map((customer, index) => (
                           <li key={index} className={styles['hs-success-item']}>
                           <div className={styles['hs-success-image']}>
                               <img src={customer.logo} alt={customer.name} />
                           </div>
                           <strong className={styles['hs-success-title']}>{customer.name}</strong>
                           {customer.description && (
                               <p className={styles['hs-success-description']}>{customer.description}</p>
                           )}
                           <p className={styles['hs-success-info']}>
                               {customer.service}
                           </p>
                       </li>
                        ))}
                    </ul>
                </div>
            </section>
        </>
    );
}

export default HsSuccess;