const MENU_ITEMS = [
    {
        label: '회사소개',
        url: '/company',
    },
    {
        label: '솔루션',
        subMenuItems: [
            {
                label: 'TMS',
                url: '/tms',
            },
            {
                label: 'AID',
                url: '/#aid',
            },
        ],
    },
    {
        label: '제품',
        subMenuItems: [
            {
                label: 'AI Help U',
                url: '/#ai-help-u',
            },
            {
                label: 'TasOn',
                url: 'https://www.tason.com/',
                target: '_blank'
            },
            {
                label: '마케팅자동화',
                url: 'https://tma.tason.com/landing/main',
                target: '_blank'
            },
            {
                label: 'CMC',
                url: '/#cmc',
            },
        ],
    },
    {
        label: '성공사례',
        url: '/success',
    },
];

export default MENU_ITEMS;